<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Wallet Redemption</h2>
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search For User name"
          class="mb-5"
          v-model="walletRedemption.searchQuery"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex flex-col">
        <div class="duration-300">
          <datatable
            :url="walletRedemption.url"
            :ajax="true"
            :ajax-pagination="true"
            :columns="walletRedemption.columns"
            :query="walletRedemption.searchQuery"
            :fillable="false"
            ref="table"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      walletRedemption: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/wallet/transactions`,
        searchQuery: '',
        columns: [
          {
            name: 'reward_walletable',
            th: 'Customer Name',
            render: redemption =>
              `${redemption?.reward_wallet?.owner?.name} ${redemption?.reward_wallet?.owner?.last_name}`
          },
          {
            name: 'amount',
            th: 'Redemption Amount',
            render: redemption =>
              this.$options.filters.formatAmount(redemption?.amount)
          },
          {
            name: 'type',
            th: 'Type',
            render: redemption =>
              redemption?.type === 'cr' ? 'Credit' : 'Debit'
          },
          {
            name: 'reward_wallet',
            th: 'Wallet Balance',
            render: redemption =>
              this.$options.filters.formatAmount(redemption?.current_balance)
          },
          {
            name: 'transaction_date',
            th: 'Transaction Date',
            render: redemption =>
              this.$moment(redemption?.transaction_date).format(
                'dddd, MMMM Do YYYY'
              )
          }
        ]
      })
    };
  }
};
</script>
